import { Step, StepList } from 'components/TripScreen/TripPlanScreen/TripPlanContent/StepList';

import { ErrorBoundary } from '@sentry/react';
import config from 'config';
import lokalise from 'utils/lokalise';
import tripscreen from 'utils/tripscreen';

// @TODO this should be URLparams context, router or something
const searchParams = new URLSearchParams(window.location.search || document.location.search);
const mapColors = searchParams.get("ui") === 'dark' ? config.mapColorSchemes.dark : config.mapColorSchemes.light;

function TripPlanContentTransit({ tripPlan, endingLocation }) {

    function getRightColumnContent(leg, i) {
        if (leg) {
            if (tripscreen.isTransitMode(leg.mode)) {
                return <StepTransit isDestination={i === tripPlan.legs.length - 1} leg={leg} legs={tripPlan.legs} />
            } else if (leg.mode === 'walk') {
                // You prob could insert the TripPlanContentStepBased here 
                return <StepWalk isDestination={i === tripPlan.legs.length - 1} leg={leg} nextLeg={tripPlan.legs[i + 1]} />
            } else if (leg.mode === 'bikeshare' || leg.mode === 'bike') {
                return <StepBike isDestination={i === tripPlan.legs.length - 1} leg={leg} nextLeg={tripPlan.legs[i + 1]} />
            } else if (leg.mode === 'car') {
                return <StepCar isDestination={i === tripPlan.legs.length - 1} leg={leg} nextLeg={tripPlan.legs[i + 1]} />
            } else if (leg.mode === 'ebikeshare') {
                return <StepBike isDestination={i === tripPlan.legs.length - 1} leg={leg} nextLeg={tripPlan.legs[i + 1]} />
            } 
        }
        return leg ? leg.mode : '';
    }

    return (
        <div className="TripPlanContent TransitDirections">
            <div className="directions">
                <StepList>
                    {tripPlan.legs?.map((leg, i) => (
                        <ErrorBoundary key={i} fallback={"..."}>
                            <Step
                                icon={tripscreen.getIcon(i, tripPlan.legs)}
                                modeIcon={leg.transitDetails?.line.type || leg.mode}
                                color={leg.route ? leg.route?.color || mapColors.transit : null}>
                                {getRightColumnContent(leg, i)}
                                {
                                    i === tripPlan.legs.length - 1 && <>
                                        {lokalise.getKey('tripplan_your_dest')}<br />
                                        {endingLocation?.address || leg?.to?.name}
                                    </>
                                }
                            </Step>
                        </ErrorBoundary>
                    ))}
                </StepList>
            </div>
        </div>
    )
}

function StepBike({ leg, nextLeg, isDestination }) {
    return (
        <div className="StepBike">
            <div className="header">
                <b>
                    {lokalise.getKey('tripplan_time_verb_to_dest', {
                        time_verb: tripscreen.getDurationWithVerb(leg.duration, lokalise.getKey('tripplan_mode_bike')),
                        destination: tripscreen.capitalize(leg?.to?.name)
                    })}
                </b> ({tripscreen.getDistance(leg.distance)})
            </div>
        </div>
    );
}
function StepWalk({ leg, nextLeg, isDestination }) {
    return (
        <div className="StepWalk">
            <div className="header">
                <b>
                    {lokalise.getKey('tripplan_time_verb_to_dest', {
                        time_verb: tripscreen.getDurationWithVerb(leg.duration, lokalise.getKey('tripplan_mode_walk')),
                        destination: tripscreen.capitalize(leg?.to?.name)
                    })}
                </b> ({tripscreen.getDistance(leg.distance)})
            </div>
        </div>
    );
}
function StepCar({ leg, nextLeg, isDestination }) {
    return (
        <div className="StepCar">
            <div className="header">
                <b>
                    {lokalise.getKey('tripplan_time_verb_to_dest', {
                        time_verb: tripscreen.getDurationWithVerb(leg.duration, lokalise.getKey('tripplan_mode_drive')),
                        destination: tripscreen.capitalize(leg?.to?.name)
                    })}
                </b> ({tripscreen.getDistance(leg.distance)})
            </div>
        </div>
    );
}
function StepTransit({ leg, nextLeg, isDestination }) {
    // console.log("transitDetails", leg);
    return (
        <>
            <div className="StepTransit">
                <div className="header">
                    <b>{lokalise.getKey('tripplan_take_the_mode', { mode: leg.mode })} {tripscreen.getDuration(leg.duration)}</b>
                </div>

                {leg?.route?.fullName} {tripscreen.capitalize(leg.route?.headSign)}<br />

                {leg?.stops && lokalise.getKey('tripplan_ride_for', { num: leg?.numberOfStops })}<br />

                {lokalise.getKey('tripplan_alight_at', { station: tripscreen.capitalize(leg.to.name) })}

            </div>
            <div className="StepTransit">
                <div className="routes">
                    <div className="route-indicator" style={{ background: leg?.route?.color || mapColors.transit }}></div>
                    <div className="route-description">
                        <b>{leg?.route?.shortName}</b> {tripscreen.capitalize(leg?.route?.fullName)}
                    </div>
                </div>
            </div>
        </>
    )
}
export default TripPlanContentTransit;
